import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { errorActions } from '../actions';
import { manageTokenActions } from '../actions/manage-tokens.actions';
import { companyRoles } from '../constants';
import { RequestState } from '../constants/request-state';
import { companiesService } from '../services';
import { manageTokensService } from '../services/manage-tokens.service';
import { CompanyStatus } from '../types/company/CompanyStatus';
import { AccessToken } from '../types/management/AccessToken';
import { Company } from '../types/management/Company';

function* wathRequestTokens() {
    yield put(manageTokenActions.setTokenRequestStatus(RequestState.request));
    try {
        const tokens: AccessToken[] = yield call(manageTokensService.getTokens);
        yield put(manageTokenActions.storeTokens(tokens));
        yield put(manageTokenActions.setTokenRequestStatus(RequestState.success));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchGenerateToken(action: ActionType<typeof manageTokenActions.generate>) {
    yield put(manageTokenActions.setGenerateRequestStatus(RequestState.request));

    try {
        const token: AccessToken = yield call(manageTokensService.saveToken, undefined, action.payload.companyId, action.payload.apis);
        yield put(manageTokenActions.storeGeneratedToken(token));
        yield put(manageTokenActions.setGenerateRequestStatus(RequestState.success));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchUpdateToken(action: ActionType<typeof manageTokenActions.update>) {
    yield put(manageTokenActions.setUpdateTokenRequestStatus(RequestState.request));

    try {
        const token: AccessToken = yield call(manageTokensService.saveToken, action.payload.tokenId, action.payload.companyId, action.payload.apis);
        yield put(manageTokenActions.tokenUpdated(token));
        yield put(manageTokenActions.setUpdateTokenRequestStatus(RequestState.success));
    } catch (e) {
        yield put(manageTokenActions.setUpdateTokenRequestStatus(RequestState.failure));
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchRevokeToken(action: ActionType<typeof manageTokenActions.revoke>) {
    try {
        yield put(manageTokenActions.setRevokeTokenRequestStatus(action.payload.tokenId, RequestState.request));
        yield call(manageTokensService.revokeToken, action.payload.tokenId);
        yield put(manageTokenActions.tokenRevoked(action.payload.tokenId));
        yield put(manageTokenActions.setRevokeTokenRequestStatus(action.payload.tokenId, RequestState.success));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(manageTokenActions.setRevokeTokenRequestStatus(action.payload.tokenId, RequestState.failure));
    }
}

function* watchRequestCompanies() {
    yield put(manageTokenActions.setCompanyRequestStatus(RequestState.request));
    try {
        const allCompanies: Company[] = yield call(companiesService.getCompanies);
        const companies: Company[] = allCompanies
            .filter(c =>
                c.status === CompanyStatus.active &&
                c.role !== companyRoles.Administrator &&
                c.role !== companyRoles.Viewer)
            .sort((a, b) => a.name.localeCompare(b.name));

        yield put(manageTokenActions.storeCompanies(companies));
        yield put(manageTokenActions.setCompanyRequestStatus(RequestState.success));

    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

export function* watchManageTokens() {
    yield takeEvery(getType(manageTokenActions.requestTokens), wathRequestTokens);
    yield takeEvery(getType(manageTokenActions.requestCompanies), watchRequestCompanies);
    yield takeEvery(getType(manageTokenActions.generate), watchGenerateToken);
    yield takeEvery(getType(manageTokenActions.update), watchUpdateToken);
    yield takeEvery(getType(manageTokenActions.revoke), watchRevokeToken);
}
